<template>
    <div class="ui-power">
        <a-spin :spinning="loading">
        <h4 class="ui-head">正在编辑角色：{{ $route.query.name || '' }}</h4>
        <a-divider orientation="left">编辑角色权限</a-divider>
		<div>
			<custemCheckBox :list="allMenus" :selectRowIds="checkedIds" :dataMap="dataMap" @selectChange="onSelectChange"></custemCheckBox>
		</div>
        <div style="margin-left: 100px;">
            <a-button type="primary" @click="onSubmit">提交修改</a-button>
        </div>
        </a-spin>
    </div>
</template>

<script>
    import * as systemApi from '@/service/modules/system';
    export default{
        data() {
            return {
				name: '',
                loading: false,
                id: 0,
                allMenus: [],
                checkedIds: [],
				dataMap: {}
            }
        },
        created() {
			this.getDataList();
            if (this.$route.query.id) {
                this.id = this.$route.query.id;
                this.getData();
            }
        },
        methods: {
            onSubmit() {
				const hasUrls = this.$store.state.system.permissionCodes;
				let codes = [];
				this.checkedIds.forEach(item => {
					if (hasUrls.includes(item)) {
						codes.push(item);
					}
				})
				let dataPowerList = [];
				let ruleSelectDataIds = this.$store.state.system.ruleSelectDataIds;
				Object.keys(ruleSelectDataIds).forEach(item => {
					if (hasUrls.includes(item)) {
						dataPowerList.push(ruleSelectDataIds[item]);
					}
				})
                this.loading = true;
                systemApi.setRoleMenu({
                    roleId: this.id,
                    codes: codes.join(','),
					dataPowerList: dataPowerList
                }).then(res => {
                    this.loading = false;
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        this.$closePage('/system/role');
                    }
                })
            },
            getData() {
                this.loading = true;
                systemApi.getRoleMenu({
                    id: this.id
                }).then(res => {
                    this.loading = false;
                    if (res.code == 200) {
						this.$store.commit('system/clearRuleDataIds', {});
						let selectedPowers = res.data.selectedPowers;
						let codesMap = {};
						let groupMapping = res.data.allPowers.reduce((prev, next) => {
							if (!next.group) {
								next.group = 0;
							}
							if (next.type === 'DATA') {
								for (let i = 0; i < selectedPowers.length; i++) {
									if (next.code === selectedPowers[i].code && selectedPowers[i].dataIdList && selectedPowers[i].dataIdList.length) {
										next.dataIdList = selectedPowers[i].dataIdList || [];
										this.$store.commit('system/setRuleDataIds', { code: next.code, dataIdList: selectedPowers[i].dataIdList });
										break;
									}
								}
							}
							let codeArr = next.code.split('_');
							let length = codeArr.length;
							if (codeArr.length > 1) {
								for(let i = length;i > 0; i--) {
									codeArr.splice(codeArr.length - 1, 1);
									let parentCodes = codeArr.join('_');
									if (codesMap[parentCodes]) {
										codesMap[parentCodes].push(next.code);
									} else {
										codesMap[parentCodes] = [next.code];
									}
								}
							} else {
								if (codesMap[next.code]) {
									codesMap[next.code].push(next.code);
								} else {
									codesMap[next.code] = [next.code];
								}
							}
							prev[next.group] ? prev[next.group].push(next) : prev[next.group] = [next];
							return prev;
						}, {});
						let keys = Object.keys(groupMapping);
						keys.forEach(key => {
							if (groupMapping[key]) {
								groupMapping[key].forEach((item, i) => {
									if (groupMapping[item.code]) {
										item.children = groupMapping[item.code];
										if (codesMap[item.code]) {
											item.childrenIds = codesMap[item.code];
										}
									}
								});
							}
						});
						this.groupMapping = groupMapping;
                        let allMenu = groupMapping[0];	// 获取所有group=0的数据
                        this.allMenus = allMenu;
                        this.checkedIds = res.data.selectedPowers.map(item => {
                            return item.code;
                        })
                    }
                })
            },
			async getDataList() {
				let ret = await systemApi.getDataList({});
				if (ret.code === 200) {
					ret.data.forEach(item => {
						this.getDataDimensionList(item.code);
					})
				}
			},
			async getDataDimensionList(code) {
				let ret = await systemApi.getDataDimensionList({
					type: code
				});
				if (ret.code === 200) {
					this.dataMap[code] = ret.data;
				}
			},
			// mergeChildrenIds(data, groups) {
			// 	if (!groups) {
			// 		groups = [];
			// 	}
			// 	data.forEach(item => {
			// 		item.groups = groups;
			// 		if (item.children) {
			// 			this.mergeChildrenIds(item.children, [...item.groups, item.code]);
			// 			let childrenIds = [];
			// 			item.children.forEach(subItem => {
			// 				if (subItem.childrenIds) {
			// 					childrenIds = childrenIds.concat(subItem.childrenIds);
			// 				}
			// 			})
			// 			item.childrenIds = item.childrenIds.concat(childrenIds);
			// 		}
			// 	})
			// },
			mergeChildrenIds(data) {
				
				function forFun(list){
					list.forEach(item => {
						if (item.children && item.children.length) {
							item.childrenIds = childCodes(item.children);
							// forFun(item.children);
						}
					})
				
					function childCodes(children) {
						let codes = [];
						children.forEach(item => {
							codes.push(item.code);
						})
						return codes;
					}
				}
				
				forFun(data);
			},
			onSelectChange(e) {
				let checked = e.checked;
				let groups = e.groups || [];
				let childrenIds = e.childrenIds || [];
				if (checked) {
					groups.forEach(group => {
						if (!this.checkedIds.includes(group)) {
							this.checkedIds.push(group);
						}
					})
					if (e.selectAll) {
						childrenIds.forEach(itemId => {
							if (!this.checkedIds.includes(itemId)) {
								this.checkedIds.push(itemId);
							}
						});
					}
					if (!this.checkedIds.includes(e.code)) {
						this.checkedIds.push(e.code);
					}
				} else {
					childrenIds.forEach(itemId => {
						if (this.checkedIds.includes(itemId)) {
							this.checkedIds.splice(this.checkedIds.indexOf(itemId), 1);
						}
					});
					this.checkedIds.splice(this.checkedIds.indexOf(e.code), 1);
					for (let i = 0; i< groups.length;i++) {
						let groupCode = groups[i];
						let groupChild = this.groupMapping[groupCode];
						let hasSelectChild = groupChild.filter(item => {
							return this.checkedIds.includes(item.code);
						})
						if (hasSelectChild.length === 0 && this.checkedIds.includes(groupCode)) {
							this.checkedIds.splice(this.checkedIds.indexOf(groupCode), 1);
						} else {
							return;
						}
					}
				}
			},
        }
    }
</script>

<style scoped>
.ui-power {
    text-align: left;
}
.ui-head {
    font-size: 17px;
    font-weight: 700;
}
.ui-title {
    font-size: 15px;
    font-weight: 700;
}
.ui-text {
    width: 180px;
    font-weight: 600;
}
.ui-text2 {
    width: 150px;
    font-weight: 500;
}
.ui-subBox {
    margin-top: 20px;
    margin-left: 30px;
}
</style>